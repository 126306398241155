import React, { useEffect, useState } from 'react'
import './InfoGraf.scss'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getInfoNews } from '../../redux/reducers/app';
import user from '../../assets/icons/user.png'
import view from '../../assets/icons/glas.png'
import HeaderADS from '../Header/components/HeaderADS/HeaderADS';
import WeAdd from '../WeAdd/WeAdd';

export default function InfoGraf() {
    const infonews = useSelector(s => s.app.infonews);
    const popular = useSelector(s => s.app.popular);
    const dispatch = useDispatch();
    const [allNews, setAllNews] = useState([])
    let lol = 0
    useEffect(() => {
        dispatch(getInfoNews());
    }, [])
    useEffect(() => {
        if (infonews.results ) {
            setAllNews(infonews.results)
        }
    }, [infonews.results])
    // let allNews = [
    //     {
    //         id: 1,
    //         image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg',
    //         title: 'looooooooooooooooooooooo ooooooooooooooooo oooooooooooooo oooooooooo ooooo llllllll',
    //         author: {
    //             username: 'лол лолович'
    //         },
    //         created: '2',
    //         views: 1
    //     },
    //     {
    //         id: 2,
    //         image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg',
    //         title: 'looooooooooooooooooooooo ooooooooooooooooo oooooooooooooo oooooooooo ooooo llllllll',
    //         author: {
    //             username: 'лол лолович'
    //         },
    //         created: '2',
    //         views: 1
    //     },
    //     {
    //         id: 3,
    //         image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg',
    //         title: 'looooooooooooooooooooooo ooooooooooooooooo oooooooooooooo oooooooooo ooooo llllllll',
    //         author: {
    //             username: 'лол лолович'
    //         },
    //         created: '2',
    //         views: 1
    //     },
    //     {
    //         id: 4,
    //         image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/IMG_logo_%282017%29.svg',
    //         title: 'looooooooooooooooooooooo ooooooooooooooooo oooooooooooooo oooooooooo ooooo llllllll',
    //         author: {
    //             username: 'лол лолович'
    //         },
    //         created: '2',
    //         views: 1
    //     },
    // ]
    return (
        <>
            <Header top={'18vh'} />
            <HeaderADS />

            <div className='InfoGraf'>
                <div className="main">
                    <div className="left">
                        <div className="main__inner">
                            <div className="top">
                                <h2 className='title'>Инфографика</h2>
                            </div>
                            <div className="news123">
                                {
                                    allNews.map((e) => {
                                        if (lol === 4) {
                                            lol = 0
                                        } else {
                                            lol++
                                        }
                                        return (
                                            <div key={e.id} style={{ backgroundImage: `url(${e.image})` }} className={`card c${lol}`}>
                                                <div className={`wrapper `}>
                                                    <div className="info">
                                                        <div className="categories">

                                                        </div>
                                                        <div className="text">
                                                            <div className="text__inner">
                                                                <Link to={`/DetailNews/${e.id}`} className="mini_title">{e.title}</Link>
                                                                <div className="author">
                                                                    <img src={user} alt="" />
                                                                    <span>{e.author.username}</span>
                                                                    <hr />
                                                                    <span>{e.created}</span>
                                                                </div>
                                                            </div>
                                                            <div className="text__views">
                                                                <img src={view} alt="" />
                                                                <span>{e.views}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                            <WeAdd />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
