import React from 'react'
import {Route, Routes} from 'react-router'
import Home from '../components/Home/Home'
import DetailNews from '../components/DetailNews/DetailNews'
import About from '../components/About/About'
import TV_program from '../components/TV_program/TV_program'
import News from '../components/News/News'
import Publication from '../components/Publication/Publication'
import Gallery from '../components/Gallery/Gallery'
import InfoGraf from '../components/InfoGraf/InfoGraf'
import Search from '../components/SerchNews/Search'
import Dashboard from "../components/Dashboard/Dashboard";

export default function MainRoutes() {
    const PUBLIC_ROUTES = [
        // {link: "", element: <Dashboard/>, id: 1},
        {link: "", element: <Home/>, id: 1},
        {link: "/DetailNews/:id", element: <DetailNews/>, id: 2},
        {link: "/About", element: <About/>, id: 3},
        {link: "/Tv-program", element: <TV_program/>, id: 4},
        {link: "/Publication", element: <Publication/>, id: 5},
        {link: "/News", element: <News/>, id: 6},
        {link: "/Gallery", element: <Gallery/>, id: 7},
        {link: "/InfoGraf", element: <InfoGraf/>, id: 8},
        {link: "/search", element: <Search/>, id: 9},
    ]
    return (
        <>
            <Routes>
                {
                    PUBLIC_ROUTES.map((item) => (
                        <Route path={item.link} element={item.element} key={item.id}></Route>
                    ))
                }
            </Routes>
        </>
    )
}
