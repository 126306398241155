import './app.scss'
import MainRoutes from './Route/MainRoutes';
import Header from './components/Header/Header';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Footer} from './components/Footer/Footer';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';
// import ym from 'yandex-metrica';
import {YMInitializer} from 'react-yandex-metrika';

function ScrollToTopOnLink({children}) {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return children;
}

function App() {
    // const location = useLocation();
    // const isHomePage = location.pathname === "/";
    // useEffect(() => {
    //   // Инициализация Яндекс.Метрики с вашим номером счетчика
    //   ym.initialize(94962603, {
    //     clickmap: true,
    //     trackLinks: true,
    //     accurateTrackBounce: true,
    //     webvisor: true,
    //   });

    //   // Отправка события "Посещение сайта"
    //   ym('hit', '/');
    // }, []);
    return (
        <div className="App">
            <ScrollToTopOnLink>

                <MainRoutes>

                </MainRoutes>
                <Footer/>
                <YMInitializer accounts={[94962603]}/>
                {/* {!isHomePage && (*/}
                {/*  <>*/}
                {/*    <Footer />*/}
                {/*    <YMInitializer accounts={[94962603]} />*/}
                {/*  </>*/}
                {/*)}*/}
            </ScrollToTopOnLink>
        </div>
    );
}

export default App;
