// /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
// import logo from '../../../../assets/images/logo.png'
// import whatsapp from '../../../../assets/icons/whatsapp.png'
// import tg from '../../../../assets/icons/tg.png'
// import inst from '../../../../assets/icons/inst.png'
// import facebook from '../../../../assets/icons/facebook.png'
import { useSelector, useDispatch } from "react-redux";
import { getADS } from '../../../../redux/reducers/app'
import './HeaderAds.scss'

export default function HeaderADS() {
  const ads = useSelector(s => s.app.ads);
  const dispatch = useDispatch();
  const [ads1, setads1] = useState({})

  const handleClick = () => {
    window.location.href =ads1.top_ads; // Переход на страницу /about
  };
  useEffect(() => {
    dispatch(getADS());
  }, [])
  useEffect(() => {
    if (ads.results) {
      setads1(ads.results.slice(-1)[0])
    }
  }, [ads.results])
  const backgroundStyle = {
    backgroundImage: `url(${ads1.top_image_or_video ? ads1.top_image_or_video : ''})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '50px 0',
    cursor : 'pointer'
  };
  return (
    <div className='ads' style={backgroundStyle} onClick={() => handleClick()} >
      <div className="container">

      </div>
    </div>
  )
}
