import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import './mainnews.scss'
import user from '../../../../assets/icons/user.png'
import view from '../../../../assets/icons/glas.png'
import { useSelector, useDispatch } from "react-redux";
import { getNews, getPopular } from '../../../../redux/reducers/app'

export default function MainNews() {
    const news = useSelector(s => s.app.news);
    const popular = useSelector(s => s.app.popular);
    const dispatch = useDispatch();
    const [allNews, setAllNews] = useState([])
    const [populars, setPopulars] = useState([])
    const [special, setSpecial] = useState(0)
    const [cat, setCat] = useState('new')
    useEffect(()=>{
        dispatch(getNews());
        dispatch(getPopular());
    }, [])
    useEffect(()=>{
        if(news.results && popular.results){
            setAllNews(news.results.slice(0,4))
            setSpecial(news.results.filter((e)=>e.is_special).slice(0,4))
            setPopulars(popular.results.slice(0,4))
        }
    }, [news.results,popular.results])
  return (
    <div className='main_news'>
      <div className="container">
        <div className="main__inner">
            <div className="top">
                <h2 className='title'>Негизги жаңылыктар</h2>

                <div className="filter">
                    <span>Фильтр</span>
                    <button onClick={()=>{setCat('popular')}} className='category__button'>Популярдуу</button>
                    <button onClick={()=>{setCat('new')}} className='category__button'>Жаңы</button>
                    <button onClick={()=>{setCat('spec')}} className='category__button'>Атайын репортаж</button>
                </div>
            </div>
            <div className="news123">
                {
                cat === 'new'?(
                    allNews.map((e)=>(
                        <a href={`/DetailNews/${e.id}`} style={{backgroundImage:`url(${e.image})`}} className="card">
                            <div className="wrapper">
                                <div className="info">
                                    <div className="categories">
                                        {
                                            e.categories.map((category)=>(
                                                <Link to={`/News`} key={category.id} className='category__button'>{category.name}</Link>
                                            ))
                                            
                                        }
                                    </div>
                                    <div className="text">
                                        <div className="text__inner">
                                            <a href={`/DetailNews/${e.id}`} className="mini_title">{e.title}</a>
                                            <div className="author">
                                                <img src={user} alt="" />
                                                <span>{e.author.username}</span>
                                                <hr />
                                                <span>{e.created}</span>
                                            </div>
                                        </div>
                                        <div className="text__views">
                                            <img src={view} alt="" />
                                            <span>{e.views}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )
                )
                ): cat === 'popular'?(
                    populars.map((e)=>(
                        <a href={`/DetailNews/${e.id}`} style={{backgroundImage:`url(${e.image})`}} className="card">
                             <div className="wrapper">
                                <div className="info">
                                    <div className="categories">
                                        {
                                            e.categories.map((category)=>(
                                                <a key={category.id} className='category__button'>{category.name}</a>
                                            ))
                                            
                                        }
                                    </div>
                                    <div className="text">
                                        <div className="text__inner">
                                            <h2 className="mini_title">{e.title}</h2>
                                            <div className="author">
                                                <img src={user} alt="" />
                                                <span>{e.author.username}</span>
                                                <hr />
                                                <span>{e.created}</span>
                                            </div>
                                        </div>
                                        <div className="text__views">
                                            <img src={view} alt="" />
                                            <span>{e.views}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )
                )
                ):(
                    special.map((e)=>(
                        <a href={`/DetailNews/${e.id}`} style={{backgroundImage:`url(${e.image})`}} className="card">
                             <div className="wrapper">
                                <div className="info">
                                    <div className="categories">
                                        {
                                            e.categories.map((category)=>(
                                                <a key={category.id} className='category__button'>{category.name}</a>
                                            ))
                                            
                                        }
                                    </div>
                                    <div className="text">
                                        <div className="text__inner">
                                            <h2 className="mini_title">{e.title}</h2>
                                            <div className="author">
                                                <img src={user} alt="" />
                                                <span>{e.author.username}</span>
                                                <hr />
                                                <span>{e.created}</span>
                                            </div>
                                        </div>
                                        <div className="text__views">
                                            <img src={view} alt="" />
                                            <span>{e.views}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )
                )
                )
                }
            </div>
        </div>
    </div>
    </div>
  )
}
